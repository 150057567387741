import React from 'react';

import styled, { keyframes } from 'styled-components';
import AnimationContainer from './components/animation-container';
import BaffleText from './components/baffle-text';
import Baffle from 'baffle-react';
import Fade from 'react-reveal/Fade';
import { withRouter } from 'react-router';

class PageRevealer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animation: true,
      complete: false,
      hide: false,
      classname: '',
      canUpdate: true,
      opacity: 1,
    };
  }

  componentDidMount() {
    if (window.sessionStorage.getItem('canShow') == 'Not Show') {
      this.setState({ animation: false });
    }
    window.sessionStorage.setItem('canShow', 'Not Show');
    setTimeout(() => {
      this.setState({ classname: 'slide-in', canUpdate: false });
      setTimeout(() => {
        this.setState({ classname: 'slide-out', opacity: 0 });
        setTimeout(() => {
          this.setState({ animation: false });
        }, 400);
      }, 400);
    }, 1300);
  }

  render() {
    const RevealContainer = styled.div`
      position: fixed;
      z-index: 1000;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      background-color: #253648;
      opacity: ${this.state.opacity};
      visibility: visible;
      overflow-x: hidden !important;
      overflow-y: hidden !important;
      font-size: 50px;
      color: #fff;
      text-transform: uppercase;
      font-weight: 700;
      @media (max-width: 500px) {
        font-size: 30px;
      }
    `;
    if (!this.state.animation) return null;
  
    return (
      <>
        <div className={'sliderr ' + this.state.classname}></div>
        <RevealContainer id="reveal_container">
          <div
            style={{
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Fade>
              <Baffle
                speed={90}
                characters={'ABCDEFGHIJKLMNOPQRSTUVWXYZ'}
                obfuscate={false}
                update={this.state.canUpdate}
                revealDuration={60}
                revealDelay={60}
              >
                {this.props.location.pathname=="/noticias"?"MMARG NOTICIAS":"MMARG"}
              </Baffle>
            </Fade>
          </div>
        </RevealContainer>
      </>
    );
  }
}

export default withRouter (PageRevealer);
