import React, { Component, Suspense } from 'react';
import Layout from './components/Layout/';
import {
  Route,
  Switch,
  BrowserRouter as Router,
  withRouter,
} from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import 'mapbox-gl/dist/mapbox-gl.css';

// Import Css
import './assets/css/materialdesignicons.min.css';
import './Apps.scss';

import './assets/css/colors/default.css';

// Include Routes
import routes from './routes';

function withLayout(WrappedComponent, hasDarkTopBar) {
  // ...and returns another component...
  return class extends React.Component {
    render() {
      return (
        <Layout hasDarkTopBar={hasDarkTopBar}>
          <WrappedComponent></WrappedComponent>
        </Layout>
      );
    }
  };
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state={canShow:false}
  }


  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  };
  render() {
    return (
      <React.Fragment>
        <Router>
          <Suspense fallback={this.Loader()}>
            <Switch>
              {routes.map((route, idx) =>
                route.isWithoutLayout ? (
                  <Route
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                    key={idx}
                  />
                ) : (
                  <Route
                    path={route.path}
                    exact
                    component={withLayout(route.component, route.isTopbarDark)}
                    key={idx}
                  />
                ),
              )}
            </Switch>
          </Suspense>
        </Router>
        <div >
        <div
          onClick={() => {
            window.open('https://wa.me/5491132851634', '_blank');
          }}
          style={{
            position: 'fixed',
            zIndex: 90,
            backgroundColor: '#25D366',
            right: window.innerWidth>800?10:5,
            bottom:  285,
            width: 40,
            height: 40,
            borderRadius: 200,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <i className="mdi mdi-whatsapp icons" style={{ fontSize: 27 }}></i>
        </div>
        <div
          onClick={() => {
            window.open(
              'https://www.instagram.com/mercadodemetales/?hl=en',
              '_blank',
            );
          }}
          className="instabg"
          style={{
            position: 'fixed',
            zIndex: 90,
            right: window.innerWidth>800?10:5,
            bottom: 150,
            width: 40,
            height: 40,
            borderRadius: 200,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <i className="mdi mdi-instagram icons" style={{ fontSize: 25 }}></i>
        </div>
        <div
          onClick={() => {
            window.open(
              'https://www.linkedin.com/company/mercado-de-metales-y-futuros-sa/',
              '_blank',
            );
          }}
          style={{
            position: 'fixed',
            zIndex: 90,
            right: window.innerWidth>800?10:5,
            backgroundColor: '#0B66C2',
            bottom: 195,
            width: 40,
            height: 40,
            borderRadius: 200,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <i className="mdi mdi-linkedin icons" style={{ fontSize: 25 }}></i>
        </div>
        <div
          onClick={() => {
            window.open(
              'https://twitter.com/mmargde?lang=en',
              '_blank',
            );
          }}
          style={{
            position: 'fixed',
            zIndex: 90,
            right: window.innerWidth>800?10:5,
            backgroundColor: '#1EA1F2',
            bottom: 240,
            width: 40,
            height: 40,
            borderRadius: 200,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <i className="mdi mdi-twitter icons" style={{ fontSize: 25 }}></i>
        </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
